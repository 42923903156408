<template>
  <div>
    <div class="simiot-table-operation-buttons">
      <a-button
        type="primary"
        @click="showUploadModal"
        v-if="isShowMenu('agent_files/upload')"
      >
        上传文件
      </a-button>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a
            v-if="isShowMenu('agent_files/download')"
            :href="record.download_url"
            download
          >下载</a>

          <a
            class="delete"
            @click="deleteAgentFile(record.id)"
            v-if="isShowMenu('agent_files/delete')"
          >删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <upload
      v-if="isShowUploadModal"
      :visible.sync="isShowUploadModal"
      :agent-id="agentId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findAgentFiles, deleteAgentFile } from '@/api/agent_file'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'AgentFileList',
  components: {
    Pagination,
    Upload: () => import('@/views/agent_files/Upload')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowUploadModal: false,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },

    columns() {
      return [
        {
          title: '上传时间',
          dataIndex: 'created_at'
        },
        {
          title: '文件名称',
          dataIndex: 'name'
        },
        {
          title: '文件大小',
          dataIndex: 'attachment_size'
        },
        {
          title: '上传者',
          dataIndex: 'upload_user_name'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showUploadModal() {
      this.isShowUploadModal = true
    },

    fetchData() {
      this.loading = true
      findAgentFiles(
        Object.assign({ agent_id: this.agentId }, this.query)
      ).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },

    // 删除协议
    deleteAgentFile(id) {
      const vm = this
      this.$confirm({
        title: '确定删除此文件吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteAgentFile(id).then(() => {
            vm.fetchData()
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.delete {
  color: red;
}
</style>
