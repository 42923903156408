import request from '@/utils/request'

// 查询客户文件资料
export function findAgentFiles(params) {
  return request({
    url: `/agent_files`,
    method: 'get',
    params: params
  })
}

// 创建客户文件协议
export function createAgentFile(data) {
  return request({
    url: `/agent_files`,
    method: 'post',
    data
  })
}

// 删除客户文件资料
export function deleteAgentFile(id) {
  return request({
    url: `/agent_files/${id}`,
    method: 'delete'
  })
}
